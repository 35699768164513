import React, { useState, useEffect } from "react";
import "./Users.scss";
import { Nav } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import InputboxComponent from "../components/Inputbox";
import OrdersRow from "../components/OrdersRow";
import Select from "react-select";
import {
  getOrderByFilter,
  updateOrderStatus,
  GetAllOrders,
} from "../api/order";
import { CustomError, CustomSuccess } from "../components/Toast";
import _ from "lodash";
import Pagination from "react-js-pagination";
export default function Orders() {

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [orderList, setOrderList] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  let ordersLength;
  debugger;
  useEffect(() => {
    fetchOrderData();
  }, [page]);
  useEffect(() => {
    const userData = localStorage.getItem("userId");
    if (userData === null) {
      
      <Redirect push to="/" />;
    }
  }, []);
  const FetchAllOrders = async () => {
    let AllOrdersApiResult = await GetAllOrders(page, limit);
    AllOrdersApiResult = _.get(AllOrdersApiResult, "data");
    AllOrdersApiResult = _.get(AllOrdersApiResult, "result");
    setOrderList(AllOrdersApiResult);
    setIsLoading(false);
    console.log(orderList);
  };
  // const fetchAllOrders = () => {
  //   setIsLoading(true);
  //   GetAllOrders(page, limit)
  //     .then(({ data }) => {
  //       if (data.success) {
  //         setOrderList(data.result);
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  //   console.log(orderList);
  // };

  const fetchOrderData = () => {
    setIsLoading(true);
    getOrderByFilter(0, 0, limit, page)
      .then(({ data }) => {
        if (data.success) {
          setOrders(data.result);
          setTotalRecords(data.totalRecords);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const orderStatusUpdate = (orderStatusObject) => {
    
    updateOrderStatus(orderStatusObject)
      .then(({ data }) => {
        
        if (data.success) {
          
          CustomSuccess("Order Status Updated");
          fetchOrderData();
        }
      })
      .catch((err) => {
        CustomError("Something went wrong");
        setIsLoading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  console.log("Orders", orders);
  const Options = [
    { value: "Fixed Cart Discount", label: "Fixed Cart Discount" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <div className="main">
      <div className="panel__conatainer">
        <div className="panel__heading">All Orders</div>
        <div>
          <Nav variant="pills" defaultActiveKey="/orders">
            <Nav.Link
              to="/orders"
              // href="/dashboard/orders"
              as={Link}
              className="posting__nav__link"
              onClick={()=>setSelectedTab(0)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#11d493",
                }}
              >
                {/* {ordersLength.length} */}
                23
              </div>
              All
            </Nav.Link>
            <Nav.Link
              to="/orders/Processing"
              as={Link}
              className="posting__nav__link"
              eventKey="link-1"
              onClick={()=>setSelectedTab(1)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#5C3DFF",
                }}
              >
                3103
              </div>
              Processing
            </Nav.Link>
            <Nav.Link
              to="/orders/RMO"
              as={Link}
              className="posting__nav__link"
              eventKey="link-2"
              onClick={()=>setSelectedTab(13)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#c832fa",
                }}
              >
                30
              </div>
              Required Manual Ordering
            </Nav.Link>
            <Nav.Link
              to="/orders/Approved"
              as={Link}
              className="posting__nav__link"
              eventKey="link-3"
              onClick={()=>setSelectedTab(5)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#09c956",
                }}
              >
                100
              </div>
              Approved
            </Nav.Link>
            <Nav.Link
              to="/orders/Prepared"
              as={Link}
              className="posting__nav__link"
              eventKey="link-4"
              onClick={()=>setSelectedTab(17)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#4287f5",
                }}
              >
                100
              </div>
              Prepared
            </Nav.Link>
            <Nav.Link
              to="/orders/Shipped"
              as={Link}
              className="posting__nav__link"
              eventKey="link-5"
              onClick={()=>setSelectedTab(16)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#f3fa34",
                }}
              >
                100
              </div>
              Shipped
            </Nav.Link>
            <Nav.Link
              to="/orders/Deliverd"
              as={Link}
              className="posting__nav__link"
              eventKey="link-6"
              onClick={()=>setSelectedTab(14)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#33f5cb",
                }}
              >
                100
              </div>
              Delivered
            </Nav.Link>
            <Nav.Link
              to="/orders/Returned"
              as={Link}
              className="posting__nav__link"
              eventKey="link-7"
              onClick={()=>setSelectedTab(15)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#e82c55",
                }}
              >
                100
              </div>
              Returned
            </Nav.Link>
            <Nav.Link
              to="/orders/Cancelled"
              as={Link}
              className="posting__nav__link"
              eventKey="link-8"
              onClick={()=>setSelectedTab(6)}
            >
              <div
                className="posting__nav__link__badge"
                style={{
                  backgroundColor: "#ff4d4d",
                }}
              >
                100
              </div>
              Cancelled
            </Nav.Link>
          </Nav>
        </div>
        <div className="panel__main__selctors__filters">
          <InputboxComponent
            placeholder="Search"
            ClassName="input__box"
            type="search"
          />
          <div className="panel__main__selctors__filters__entity">
            <Select
              // defaultValue={[colourOptions[2], colourOptions[3]]}
              name="colors"
              options={Options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="panel__main__selctors__filters__entity">
            <Select
              // defaultValue={[colourOptions[2], colourOptions[3]]}
              name="colors"
              options={Options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="panel__main__selctors__filters__entity">
            <Select
              // defaultValue={[colourOptions[2], colourOptions[3]]}
              name="colors"
              options={Options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="panel__main__selctors__filters__entity">
            <Select
              // defaultValue={[colourOptions[2], colourOptions[3]]}
              name="colors"
              options={Options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="panel__main__plans">
          <div className="panel__main__role__manager__content">
            <div className="panel__main__freelancer">
              <div className="panel__main__heading__row">
                <div className="panel__main__heading__row__entry">ID</div>
                {/* <div
                  className="panel__main__heading__row__entry"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "1em",
                    alignItems: "center",
                  }}
                >
                  <span>Placed/</span>
                  <span>Created</span>
                </div> */}
                <div className="panel__main__heading__row__entry">Amount</div>
                <div className="panel__main__heading__row__entry">Items</div>
                <div className="panel__main__heading__row__entry">Country</div>
                <div className="panel__main__heading__row__entry">State</div>
                {/* <div className="panel__main__heading__row__entry">Info</div> */}
                <div className="panel__main__heading__row__entry">Status</div>
                <div className="panel__main__heading__row__entry">Show</div>
                {/* <div className="panel__main__heading__row__entry">Note</div> */}
              </div>
              {/* {orderList.map((list, Index) => (
                <OrdersRow key={Index} ordersData={list} />
              ))} */}
              {isLoading ? (
                <div
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "30px",
                    fontSize: "25px",
                  }}
                >
                  LOADING . . .
                </div>
              ) : ( 

                orders.map((order, o) => {
                  let data = null;
                  if (selectedTab === 0){
                    data= order;
                  } else if (order.orderStatusLookupDetailIdfk === selectedTab){
                    data= order;
                  }
                  console.log("data",data)
                  if (data != null){
                    return(
                    <OrdersRow
                      orderObject={data}
                      updateStatus={orderStatusUpdate}
                    />
                  )
                    }else {
                      return false
                    }
                  
                  })
              )}

              <div className="page__slider">
                <div className="slider" style={{backgroundColor: "black", margin: "15px"}}>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={limit}
                    onChange={handlePageChange}
                    // linkClass="AppPrimaryColor"
                    // activeClass="AppIconBackgroundColor"
                  />
                  {/* <Nav variant="pills" defaultActiveKey="/dashboard/orders">
                    <Nav.Link
                      to="/dashboard/orders"
                      href="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                    >
                      1
                    </Nav.Link>
                    <Nav.Link
                      to="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                      eventKey="link-1"
                    >
                      2
                    </Nav.Link>
                    <Nav.Link
                      to="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                      eventKey="link-2"
                    >
                      3
                    </Nav.Link>
                    <Nav.Link
                      to="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                      eventKey="link-3"
                    >
                      4
                    </Nav.Link>
                    <Nav.Link
                      to="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                      eventKey="link-4"
                    >
                      5
                    </Nav.Link>
                    <Nav.Link
                      to="/dashboard/orders"
                      as={Link}
                      className="nav__link__horizontal"
                      eventKey="link-5"
                    >
                      6
                    </Nav.Link>
                  </Nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
