module.exports = {
  VALIDATION_REGEX: {
    DIGITS_ONLY: /^[0-9]*$/,
    DIGITIS_WITH_DECIMAL_POINT: /^[0-9]\d*(\.\d+)?$/,
    ALPHABETS_ONLY: /^[a-zA-Z ]+$/,
    EMAIL_REGEX: /\S+@\S+\.\S+/,
    PHONE_NUMBER: /^[0-9*#+]+$/,
    DATE_YYYY_MM_DD: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
    AMAZON_ORDER_NUMBER: /^[A-Za-z0-9]*$/,
  },
};
