import axios from "axios";

export const registerUser = (data) => {
  return axios.post(
    process.env.REACT_APP_BASEURL + "api/Account/SignUp",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const loginUser = (data) => {
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "api/Account/SignIn",
    data,
    {
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};
