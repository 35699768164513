import axios from "axios";

export const saveOrder = (data) => {
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/saveOrder",
    data,
    {
      headers: {
        ["Content-Type"]: "application/json",
      },
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const GetAllOrders = (pageNumber, pageLimit) => {
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL +
      "/api/Order/GetAll?limit=" +
      pageLimit +
      "&page=" +
      pageNumber
  );
};

export function getOrderById(orderId) {
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/GetById?id=" + orderId
  );
}

export function getOrderByUserId(userId) {
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL +
      "/api/Order/GetOrderByUserId?id=" +
      userId
  );
}

export function getOrderByFilter(userId, statusId, limit, page) {
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL +
      "/api/Order/GetOrderByFilter?userId=" +
      userId +
      "&statusId=" +
      statusId +
      "&limit=" +
      limit +
      "&page=" +
      page
  );
}

export const updateOrderStatus = (data) => {
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/updateOrderStatus",
    data,
    {
      headers: {
        ["Content-Type"]: "application/json",
      },
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};

export const updateOrderProductStatus = (data) => {
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/UpdateOrderLineItemStatus",
    data,
    {
      headers: {
        ["Content-Type"]: "application/json",
      },
      ["axios-retry"]: {
        retries: 5,
      },
    }
  );
};
export const addUpdateOrderDetailStatus = (object) => {
  // const params = {
  //   Id,
  //   OrderLineIdfk,
  //   OrderLineKey,
  //   OrderLineValue,
  // };
  
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/AddUpdateOrderLineItemMeta",
    object
  );
};
export const updateOrderLineItemsStatus = (lineItemObject) => {
  return axios.post(
    process.env.REACT_APP_NEW_BASEURL + "/api/Order/UpdateOrderLineItemStatus",
    lineItemObject
  );
};
