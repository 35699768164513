import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import "./Users.scss";
import Select from "react-select";
import {
  getParentCategories,
  getCategoriesByParentId,
  updateCategoryApi,
} from "../api/category";
import { CustomError, CustomSuccess } from "../components/Toast";
import { getBase64 } from "../components/Common";
import ButtonComponent from "../components/Button";
import _ from "lodash";

export default function UpdateCategory() {
  const [parentId, setParentId] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [parentCategoriesMain, setParentCategoriesMain] = useState([]);
  const [childCategoriesMain, setChildCategoriesMain] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [fileString, setFileString] = useState("");
  const [fileStringHover, setFileStringHover] = useState("");
  const [isFeature, setIsFeature] = useState(false);

  useEffect(() => {
    fetchCategories();
    const userData = localStorage.getItem("userId");
    if (!userData) {
      <Redirect push to="/" />;
    }
  }, []);

  const fetchCategories = () => {
    setIsLoading(true);
    getParentCategories()
      .then(({ data }) => {
        if (data.success) {
          let Options = [];
          for (let index = 0; index < data.result.length; index++) {
            const element = data.result[index];
            Options.push({ value: element.id, label: element.name });
          }
          setParentCategoriesMain(data.result);
          setParentCategories(Options);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fetchCategoriesById = (data) => {
    setIsLoading(true);
    selectParentCategory(data);
    setSelectedCategory(null);
    getCategoriesByParentId(data.value)
      .then(({ data }) => {
        
        if (data.success) {
          let Options = [];
          for (let index = 0; index < data.result.length; index++) {
            const element = data.result[index];
            Options.push({ value: element.id, label: element.name });
          }
          setChildCategoriesMain(data.result);
          setChildCategories(Options);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fileSelectedHandler = async (event) => {
    const element = event.target.files[0];
    await getBase64(element, (result) => {
      setFileString(result);
    });
  };

  const fileSelectedHoverHandler = async (event) => {
    const elementHover = event.target.files[0];
    await getBase64(elementHover, (resultHover) => {
      setFileStringHover(resultHover);
    });
  };

  const update = () => {
    setIsLoading(true);
    let categoryObjectUpdated = null;

    

    if (selectedCategory) {
      categoryObjectUpdated = {
        Id: selectedCategory.id,
        ParentId: selectedCategory.parentId,
        Name: selectedCategory.name,
        Slug: selectedCategory.slug,
        DateCreated: "2021-10-20T15:02:04.131Z",
        DateModified: "2021-10-20T15:02:04.131Z",
        CreatedBy: 0,
        ModifiedBy: 0,
        Image: fileString,
        HoverImage: fileStringHover,
        IsFeature: isFeature,
        Child_category: [null],
      };
    } else {
      categoryObjectUpdated = {
        Id: selectedParentCategory.id,
        ParentId: 0,
        Name: selectedParentCategory.name,
        Slug: selectedParentCategory.slug,
        DateCreated: "2021-10-20T15:02:04.131Z",
        DateModified: "2021-10-20T15:02:04.131Z",
        CreatedBy: 0,
        ModifiedBy: 0,
        Image: fileString,
        HoverImage: fileStringHover,
        IsFeature: isFeature,
        Child_category: [null],
      };
    }

    updateCategoryApi(categoryObjectUpdated)
      .then(({ data }) => {
        if (data.success) {
          CustomSuccess("Category Updated");
        } else {
          CustomError("Category Not Updated");
        }
        setIsLoading(false);
      })
      .catch(() => {
        CustomError("Something went wrong");
        setIsLoading(false);
      });
  };

  const selectCategory = (data) => {
    const categoryId = data.value;
    let categoryObject = _.find(childCategoriesMain, { id: categoryId });
    if (categoryObject) {
      setSelectedCategory(categoryObject);
      setIsFeature(categoryObject.isFeature);
      if (categoryObject.image) {
        setFileString(process.env.REACT_APP_BASEURL + categoryObject.image);
      } else {
        setFileString("");
      }

      if (categoryObject.hoverImage) {
        setFileStringHover(
          process.env.REACT_APP_BASEURL + categoryObject.hoverImage
        );
      } else {
        setFileStringHover("");
      }
    }
  };

  const selectParentCategory = (data) => {
    const categoryId = data.value;
    let categoryObject = _.find(parentCategoriesMain, { id: categoryId });
    if (categoryObject) {
      setSelectedParentCategory(categoryObject);
      setIsFeature(categoryObject.isFeature);
      if (categoryObject.image) {
        setFileString(process.env.REACT_APP_BASEURL + categoryObject.image);
      } else {
        setFileString("");
      }

      if (categoryObject.hoverImage) {
        setFileStringHover(
          process.env.REACT_APP_BASEURL + categoryObject.hoverImage
        );
      } else {
        setFileStringHover("");
      }
    }
  };

  const toggleFeatured = (data) => {
    setIsFeature(data.currentTarget.checked);
  };

  return (
    <div className="main">
      <div className="panel__conatainer">
        <div className="panel__heading">Update Categories</div>
        <div className="panel__main__selctors__filters">
          <div className="panel__main__selctors__filters__entity">
            <Select
              name="colors"
              options={parentCategories}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => fetchCategoriesById(e)}
            />
          </div>
          <div className="panel__main__selctors__filters__entity">
            <Select
              name="colors"
              options={childCategories}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => selectCategory(e)}
            />
          </div>
          <div className="panel__main__selctors__filters__entity">
            <input
              className="styled-checkbox"
              id="styled-checkbox-11"
              type="checkbox"
              value="value1"
              checked={isFeature}
              onClick={(e) => toggleFeatured(e)}
            />
            <label htmlFor="styled-checkbox-11">Make it feature</label>
          </div>
        </div>
        <div className="panel__main__selctors__filters">
          <div className="panel__main__selctors__filters__entity">
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={fileSelectedHandler}
            />
          </div>
          <div className="panel__main__selctors__filters__entity"></div>
          {fileString ? (
            <div className="panel__main__selctors__filters__entity">
              <img src={fileString} height="200" width="200" />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="panel__main__selctors__filters">
          <div className="panel__main__selctors__filters__entity">
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={fileSelectedHoverHandler}
            />
          </div>
          <div className="panel__main__selctors__filters__entity"></div>
          {fileStringHover ? (
            <div className="panel__main__selctors__filters__entity">
              <img src={fileStringHover} height="200" width="200" />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="panel__main__selctors__filters">
          <div className="panel__main__selctors__filters__entity">
            <ButtonComponent
              variant="btn"
              placeholder="Update"
              onClick={() => update()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
