import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import DashboardPanel from "./screens/DashboardPanel";
import categories from "./screens/UpdateCategory";
import Orders from "./screens/Orders";
import OrderDetail from "./screens/OrderDetails";
import ProductsList from "./screens/Products";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <div className="dasboard__container">
          <Header />
          <SideBar />
          <Route path="/dashboard" component={DashboardPanel} />
          <Route path="/categories" component={categories} />
          <Route path="/orders" component={Orders} />
          <Route path="/order-detail" component={OrderDetail} />
          <Route path="/products" component={ProductsList} />
        </div>
      </Switch>
    </Router>
  );
}

export default App;
