import React, { useState, useEffect } from "react";
import "./DashboardPanel.scss";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import DetailsRow from "../components/DetailsRow";
import InputboxComponent from "../components/Inputbox";
import barcodePic from "../assets/barcodePic.png";
import Select from "react-select";
import TrackOrderProduct from "../components/TrackOrderProduct";
import ProductList from "../components/ProductList";
import "./OrderPanel.scss";
import _ from "lodash";
import { updateOrderStatus } from "../api/order";
export default function OrderDetails(props) {
  const orderDetailObject = props.location.state.orderDetailObject;
  console.log("orderDetailObject",orderDetailObject);
  const [approved, setApproved] = useState(false);
  const [approval, setApproval] = useState(
    orderDetailObject.orderStatusLookupDetailIdfk
  );
  const [disapproval, setDisapproval] = useState(
    orderDetailObject.orderStatusLookupDetailIdfk
  );
  const [declined, setDeclined] = useState(false);
  const [orderProducts, setOrderProducts] = useState(
    orderDetailObject.orderLineItem
  );
  console.log(
    "orderStatusLookupDetailIdfk: ",
    orderDetailObject.orderStatusLookupDetailIdfk
  );
  const [orderId, setOrderId] = useState(orderDetailObject.id);
  const [userId, setUserId] = useState(orderDetailObject.userId);
  var [statusIdfk, setStatusIdfk] = useState(
    orderDetailObject.orderStatusLookupDetailIdfk != null
      ? orderDetailObject.orderStatusLookupDetailIdfk
      : null
  );
  console.log("orderID", orderId);
  console.log("userId", userId);
  console.log("statusIdfk", statusIdfk);
  
  let addressName = "";
  let addressEmail = "";
  let addressPhone = "";
  let addressDetail = "";
  let orderProductLink = "";
  addressName = _.find(orderDetailObject.orderMetaDTO, { orderKey: "Name" });
  if (addressName) {
    addressName = addressName.orderValue;
  } else {
    addressName = "No Name";
  }

  addressEmail = _.find(orderDetailObject.orderMetaDTO, { orderKey: "Email" });
  if (addressEmail) {
    addressEmail = addressEmail.orderValue;
  } else {
    addressEmail = "No Email";
  }

  addressPhone = _.find(orderDetailObject.orderMetaDTO, { orderKey: "Phone" });
  if (addressPhone) {
    addressPhone = addressPhone.orderValue;
  } else {
    addressPhone = "No Phone";
  }

  addressDetail = _.find(orderDetailObject.orderMetaDTO, {
    orderKey: "Address",
  });
  if (addressDetail) {
    addressDetail = addressDetail.orderValue;
  } else {
    addressDetail = "No address";
  }

  orderProductLink = _.find(orderDetailObject.orderMetaDTO, {
    orderKey: "amazonLink",
  });
  if (orderProductLink) {
    orderProductLink = orderProductLink.orderValue;
  } else {
    orderProductLink = "No Link";
  }
  const handleApprove = () => {
    
    setApproved(true);
    setDeclined(false);
    setDisapproval("");
    statusIdfk = 5;
    let statusObject = {
      Id: orderId,
      StatusIdfk: statusIdfk,
      UserId: userId,
    };
    console.log("object", statusObject);
    
    updateOrderStatus(statusObject)
      .then((response) => {
        
        setApproval(response.data.result.orderStatusLookupDetailIdfk);
        console.log("response", response);
      })
      .catch((err) => {
        

        console.log("err", err);
      });
  };
  const handleDecline = () => {
    
    setDeclined(true);
    setApproved(false);
    setApproval("");
    statusIdfk = 6;
    let statusObject = {
      Id: orderId,
      StatusIdfk: statusIdfk,
      UserId: userId,
    };
    console.log("object", statusObject);
    // setDisapproval(statusIdfk);
    // console.log(disapproval);
    
    updateOrderStatus(statusObject)
      .then((response) => {
        
        setDisapproval(response.data.result.orderStatusLookupDetailIdfk);
        console.log(disapproval);
        console.log("response", response);
      })
      .catch((err) => {
        

        console.log("err", err);
      });
  };
  const Options = [
    { value: "Fixed Cart Discount", label: "Fixed Cart Discount" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <div className="panel__conatainer__empty">
      <div className="panel__conatainer__empty__row">
        <Card>
          <div className="order__details__header">
            <div className="order__details__heading">Order Details</div>
            <div className="order__details__cta">
              {approval === 5 || approved ? (
                <Button placeholder="Approved" variant="upload-btn" />
              ) : (
                <Button
                  placeholder="Approve"
                  variant="btn"
                  onClick={() => handleApprove()}
                />
              )}
              <div className="order__details__cta__divider"></div>
              {disapproval === 6 || declined ? (
                <Button placeholder="Declined" variant="upload-btn" />
              ) : (
                <Button
                  placeholder="Decline"
                  variant="btn-delete"
                  onClick={() => handleDecline()}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <div className="order__details__customer">
            <DetailsRow property="Customer" value={addressName} />
            <DetailsRow property="Shipping Address" value={addressDetail} />
            <DetailsRow property="Customer Email" value={addressEmail} />
            <DetailsRow property="Customer Phone No." value={addressPhone} />
            <DetailsRow
              property="Billing Address"
              value="Same as delivery address"
            />
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Card>
          <div
            className="order__details__product__list"
            style={{ width: "100%" }}
          >
            {orderProducts.map((product, p) => (
              <ProductList product={product} wholeOrder={orderDetailObject} />
            ))}
          </div>
          <div className="details__row__right"></div>
        </Card>
      </div>
      {/* <div className="panel__conatainer__empty__row">
        <Card>
          <div className="order__details__shipping">
            <div className="order__details__shipping__heading">
              Shipment Tracking
            </div> */}
      {/* <TrackOrderProduct /> */}
      {/* </div>
        </Card>
      </div> */}
      {/* <div className="panel__conatainer__empty__row">
        <Card>
          <div className="order__details__shipping__heading">
            Shipping Status: Prepared
          </div>
          <DetailsRow property="Order Number" value="#2464" />
          <DetailsRow property="Place on" value="14/04/2010" />
          <DetailsRow property="Payment Type" value="Credit Card14/04/2010" />
          <DetailsRow property="Checkout id" value="1313414" />
          <DetailsRow property="User Email" value="alex@gmail.com" />
          <DetailsRow property="User Phone" value="+123 45678902" />
        </Card>
        <Card>
          <div className="order__details__customer">
            <div className="order__details__shipping__heading">Deliver To</div>
            <DetailsRow property="Customer" value="Wasif Awan Awan" />
            <DetailsRow
              property="Shipping Address"
              value="3862  Clement Street Atlanta GA Georgia"
            />
            <DetailsRow
              property="Note"
              value={
                <InputboxComponent
                  textarea="textarea"
                  ClassName="input__box"
                  placeholder="Note here"
                />
              }
            />
            <DetailsRow
              property="Customer Email"
              value="awanwasif777@gmail.com"
            />
            <DetailsRow
              property="Customer Phone No."
              value="+92 242 24242424"
            />
            <DetailsRow
              property="Billing Address"
              value="Same as delivery address"
            />
            <DetailsRow property="Post/Zip Code" value="2343434" />
          </div>
        </Card>
      </div> */}
      {approved ? (
        <div className="panel__conatainer__empty__row">
          <Card>
            <div className="order__details__shipping__heading">
              Card Transaction
            </div>
            <DetailsRow property="Authorized" value="Aur-162" />
            <DetailsRow property="Created" value="14/04/2010" />
            <DetailsRow property="UID" value="343789472389749" />
            <DetailsRow property="Action ID" value="1313414" />
            <DetailsRow property="Status" value="Approved" />
            <DetailsRow property="Card" value="XXXX XXXX ---- ----" />
            <DetailsRow property="Reference" value="Auth-che-45678902" />
            <DetailsRow property="Auth Code" value="678902" />
            <DetailsRow
              property="Transaction for order ids"
              value="678902678902"
            />
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Card>
              <div className="order__details__shipping__heading">
                Charge Card
              </div>
              <InputboxComponent
                type="text"
                ClassName="input__box"
                placeholder="Amount"
              />
              <div style={{ height: "1em" }}></div>
              <Button placeholder="Charge" variant="btn" />
            </Card>
            <div style={{ height: "2em" }}></div>
            <Card>
              <div className="order__details__shipping__heading">
                Send Notification
              </div>
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                name="colors"
                options={Options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <div style={{ height: "1em" }}></div>
              <Button placeholder="Send Notification" variant="btn" />
            </Card>
          </div>
        </div>
      ) : null}
    </div>
  );
}
