import React from "react";
import "./Users.scss";
import InputboxComponent from "../components/Inputbox";
import ProductsRow from "../components/ProductsRow";
import Select from "react-select";
import _ from "lodash";
import Pagination from "react-js-pagination";
import { GetAllProducts, SearchProducts } from "../api/product";
export default class Products extends React.Component {
  constructor(props) {
    super(props);
    let ProductListPageNumber = 1;
    if (this.props.location.state) {
      ProductListPageNumber = this.props.location.state.pageNumber;
    }
    this.state = {
      productList: [],
      loader: false,
      pageNo: ProductListPageNumber,
      pageLimit: 10,
      TotalRecords: 0,
    };
  }
  async componentDidMount() {
    this.FetchAllProducts(this.state.pageNo);
  }
  FetchAllProducts = async (pageNumber) => {
    this.setState({ loader: true });
    let AllProductApiResult = await GetAllProducts(
      pageNumber,
      this.state.pageLimit
    );
    AllProductApiResult = _.get(AllProductApiResult, "data");
    let totalRec = _.get(AllProductApiResult, "totalRecords");
    AllProductApiResult = _.get(AllProductApiResult, "result");
    this.setState({
      productList: AllProductApiResult,
      loader: false,
      pageNo: pageNumber,
      TotalRecords: totalRec,
    });
    console.log(this.state.productList);
  };
  SearchAllProducts = async (pageNumber, title) => {
    let AllProductsSearchApiResult = await SearchProducts(
      title,
      this.state.pageLimit,
      pageNumber
    );
    AllProductsSearchApiResult = _.get(AllProductsSearchApiResult, "data");
    let totalRec = _.get(AllProductsSearchApiResult, "totalRecords");
    AllProductsSearchApiResult = _.get(AllProductsSearchApiResult, "result");
    
    this.setState({
      loader: false,
      pageNo: pageNumber,
      TotalRecords: totalRec,
      productList: AllProductsSearchApiResult ? AllProductsSearchApiResult : [],
      // productList: AllProductsSearchApiResult ? AllProductsSearchApiResult : [],
    });
    
  };
  handlePageChange = (pageNumber) => {
    
    this.FetchAllProducts(pageNumber);
  };
  handleSearch = (event) => {
    let name = event.currentTarget.value;
    // const arr = val.split(" ");
    // for (var i = 0; i < arr.length; i++) {
    //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    // }
    // const name = arr.join(" ");
    this.SearchAllProducts(1, name);
  };
  render() {
    const Options = [
      { value: "Fixed Cart Discount", label: "Fixed Cart Discount" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    return (
      <div className="main">
        <div className="panel__conatainer">
          <div className="panel__heading">All Products</div>
          <div className="panel__main__selctors__filters">
            <InputboxComponent
              placeholder="Search"
              ClassName="input__box"
              type="search"
              onChange={this.handleSearch}
            />
            <div className="panel__main__selctors__filters__entity">
              <InputboxComponent
                placeholder="ASIN"
                ClassName="input__box"
                type="text"
              />
            </div>
            <div className="panel__main__selctors__filters__entity">
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                name="colors"
                options={Options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="panel__main__selctors__filters__entity">
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                name="colors"
                options={Options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="panel__main__selctors__filters__entity">
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                name="colors"
                options={Options}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="panel__main__plans">
            <div className="panel__main__role__manager__content">
              <div className="panel__main__freelancer">
                <div className="panel__main__heading__row">
                  <div className="panel__main__heading__row__entry">
                    Product
                  </div>
                  <div className="panel__main__heading__row__entry">ASIN</div>
                  <div className="panel__main__heading__row__entry">Stocks</div>
                  <div className="panel__main__heading__row__entry">Price</div>
                  <div className="panel__main__heading__row__entry">
                    Catogries
                  </div>
                  <div className="panel__main__heading__row__entry">
                    Options
                  </div>
                </div>
                {this.state.loader ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "30px",
                      fontSize: "30px",
                    }}
                  >
                    LOADING . . .
                  </div>
                ) : (
                  this.state.productList.map((listData, Index) => (
                    <ProductsRow key={Index} productsData={listData} />
                  ))
                )}
                <div className="page__slider">
                  <div className="slider">
                    <Pagination
                      activePage={this.state.pageNo}
                      itemsCountPerPage={this.state.pageLimit}
                      totalItemsCount={this.state.TotalRecords}
                      pageRangeDisplayed={this.state.pageLimit}
                      onChange={this.handlePageChange.bind(this)}
                      linkClass="AppPrimaryColor"
                      // activeClass="AppIconBackgroundColor"
                    />
                    {/* <Nav variant="pills" defaultActiveKey="/dashboard/products"> */}
                    {/* <Nav.Link
                        to="/dashboard/products"
                        href="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                      >
                        1
                      </Nav.Link>
                      <Nav.Link
                        to="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                        eventKey="link-1"
                      >
                        2
                      </Nav.Link>
                      <Nav.Link
                        to="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                        eventKey="link-2"
                      >
                        3
                      </Nav.Link>
                      <Nav.Link
                        to="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                        eventKey="link-3"
                      >
                        4
                      </Nav.Link>
                      <Nav.Link
                        to="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                        eventKey="link-4"
                      >
                        5
                      </Nav.Link>
                      <Nav.Link
                        to="/dashboard/products"
                        as={Link}
                        className="nav__link__horizontal"
                        eventKey="link-5"
                      >
                        6
                      </Nav.Link> */}
                    {/* </Nav> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
