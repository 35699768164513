import React, { useState } from "react";
import Button from "../components/Button";
import { Nav, Alert, Dropdown, Col, ButtonGroup } from "react-bootstrap";
import userImg from "../assets/userPic.png";
import productPic from "../assets/productPic.png";
import _ from "lodash";
import { Link } from "react-router-dom";

function Status({ value }) {
  if (value === "Approved") {
    return (
      <Alert key="live" className="status status-live">
        Accepted
      </Alert>
    );
  } else if (value === "Processing") {
    return (
      <Alert key="live" className="status status-hold">
        Pending
      </Alert>
    );
  } else if (value === "Cancelled") {
    return (
      <Alert key="live" className="status status-closed">
        Rejected
      </Alert>
    );
  } else if (value === "RMO") {
    return (
      <Alert key="live" className="status status-RMO">
        RMO
      </Alert>
    );
  } else if (value === "Prepared") {
    return (
      <Alert key="live" className="status status-Pre">
        Prepared
      </Alert>
    );
  }
  else if (value === "Shipped") {
    return (
      <Alert key="live" className="status status-Ship">
        Shipped
      </Alert>
    );
  } else if (value === "Delivered") {
    return (
      <Alert key="live" className="status status-Dlvr">
        Delivered
      </Alert>
    );
  } else if (value === "Returned") {
    return (
      <Alert key="live" className="status status-Rtrn">
        Returned
      </Alert>
    );
  }
}

export default function OrdersRow({ orderObject, updateStatus }) {
  
  let orderStatus = "";
  if (orderObject.orderStatusLookupDetailIdfk === 1) {
    orderStatus = "Processing";
  } 
  else if (orderObject.orderStatusLookupDetailIdfk === 13) {
    orderStatus = "RMO";
  }
  else if (orderObject.orderStatusLookupDetailIdfk === 17) {
    orderStatus = "Prepared";
  }
  else if (orderObject.orderStatusLookupDetailIdfk === 16) {
    orderStatus = "Shipped";
  }
  else if (orderObject.orderStatusLookupDetailIdfk === 14) {
    orderStatus = "Delivered";
  }
  else if (orderObject.orderStatusLookupDetailIdfk === 15) {
    orderStatus = "Returned";
  }
  else if (orderObject.orderStatusLookupDetailIdfk === 5) {
    orderStatus = "Approved";
  } else {
    orderStatus = "Cancelled";
  }
  const [selected, setSelected] = useState(orderStatus);

  const handleChangeAccepted = (orderId) => {
    setSelected("Approved");
    changeOrderStatus(orderId, 5);
  };
  const handleChangePending = (orderId) => {
    setSelected("Processing");
    changeOrderStatus(orderId, 1);
  };
  const handleChangeRejected = (orderId) => {
    
    setSelected("Cancelled");
    changeOrderStatus(orderId, 6);
  };
  const handleChangePrepared = (orderId) =>{
    
    setSelected("Prepared");
    changeOrderStatus(orderId, 17);
  }
  const handleChangeRMO = (orderId) =>{
    
    setSelected("RMO");
    changeOrderStatus(orderId, 13);
  }
  const handleChangeShipped = (orderId) =>{
    
    setSelected("Shipped");
    changeOrderStatus(orderId, 16);
  }
  const handleChangeDeliverd = (orderId) =>{
    
    setSelected("Deliverd");
    changeOrderStatus(orderId, 14);
  }
  const handleChangeReturned = (orderId) =>{
    
    setSelected("Returned");
    changeOrderStatus(orderId, 15);
  }

  const changeOrderStatus = (orderId, statusId) => {
    
    let orderStatusObject = {
      Id: orderId,
      userId: parseInt(localStorage.getItem("userId")),
      StatusIdfk: statusId,
    };

    updateStatus(orderStatusObject);
  };

  const orderNumberShrink = (orderNumber) => {
    const orderNum = orderNumber.split("-");
    const shrinkedOrderNumber =
      orderNum[0].substring(0, 2) + "..." + orderNum[4].slice(-2);
    return shrinkedOrderNumber;
  };

  const orderProductsImage = (product) => {
    let productImage = "";

    productImage = _.find(product.orderLineItemMetaDTO, {
      orderLineKey: "Image",
    });
    if (productImage) {
      productImage = productImage.orderLineValue;
    } else {
      productImage = productPic;
    }

    return (
      <img
        src={productImage}
        alt="productPic"
        style={{ width: "30px", marginRight: "1em" }}
      />
    );
  };

  return (
    <Nav.Link
      className="panel__main__row"
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="panel__main__row__entry">
          #{orderNumberShrink(orderObject.orderNo)}
        </div>

        {/* <div className="panel__main__row__entry">Placed</div> */}

        <div className="panel__main__row__entry">{orderObject.tamount}</div>
        <div className="panel__main__row__entry">
          {orderObject.orderLineItem.length}
        </div>
        <div className="panel__main__row__entry">
          {orderObject.orderMetaDTO[1]
            ? orderObject.orderMetaDTO[1].orderValue
            : ""}
        </div>
        <div className="panel__main__row__entry">
          {orderObject.orderMetaDTO[2]
            ? orderObject.orderMetaDTO[2].orderValue
            : ""}
        </div>
        {/* <div className="panel__main__row__entry">
          <button className="panel__main__row__entry__btn">
            View Info
            <div className="panel__main__row__entry__para">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Provident possimus illo officiis quis velit odit. Voluptate et
              magni dolorem neque!
            </div>
          </button>
        </div> */}
        <Col className="status__col">
          <Dropdown as={ButtonGroup}>
            <Status value={selected} />
            <Dropdown.Toggle
              split
              variant="dark"
              id="dropdown-custom-2"
              style={{
                backgroundColor: "#eeeeee",
                borderColor: "#eeeeee",
                color: "#444444",
                height: 36,
              }}
            />
            <Dropdown.Menu className="super-colors">
            <Dropdown.Item
                // onClick={() => handleChangeAccepted(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#c9c5c5",
                  }}
                ></div>
                None
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeAccepted(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#09C956",
                  }}
                ></div>
                Approved
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangePending(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#ffae0c",
                  }}
                ></div>
                Processing
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeRejected(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#ff4d4d",
                  }}
                ></div>
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangePrepared(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#4287f5",
                  }}
                ></div>
                Prepared
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeRMO(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#c832fa",
                  }}
                ></div>
                Required Manual Ordering
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeShipped(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#f3fa34",
                  }}
                ></div>
                Shipped
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeDeliverd(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#33f5cb",
                  }}
                ></div>
                Deliverd
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleChangeReturned(orderObject.id)}
                className="status__dropdown"
              >
                <div
                  className="status__option"
                  style={{
                    backgroundColor: "#e82c55",
                  }}
                ></div>
                Returned
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <div className="panel__main__row__entry">
          <div className="panel__main__row__entry__cta">
            <Button
              variant="link"
              toPath={{
                pathname: "/order-detail",
                state: { orderDetailObject: orderObject },
              }}
              placeholder="Show"
            />

            <div className="panel__main__row__entry__cta__divider"></div>
            {/* <button className="panel__main__row__entry__btn">
              View Note
              <div className="panel__main__row__entry__para">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Provident possimus illo officiis quis velit odit. Voluptate et
                magni dolorem neque!
              </div>
            </button> */}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-direction",
          width: "100%",
          marginTop: "1em",
        }}
      >
        {orderObject.orderLineItem.map((product, prodIndex) =>
          orderProductsImage(product)
        )}
      </div>
    </Nav.Link>
  );
}
