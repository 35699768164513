import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import "./Login.scss";
import { Form } from "react-bootstrap";
import Inputbox from "../components/Inputbox";
import Button from "../components/Button";
import { loginUser } from "../api/user";
import LoadingPage from "../components/LoadingPage";
import { CustomError, CustomSuccess } from "../components/Toast";
import DashboardPanel from "./DashboardPanel";
import { VALIDATION_REGEX } from "../Constants";
import _ from "lodash";
export default function Login() {
  const history = useHistory;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [emailError, setEmailError] = useState(false);
  useEffect(() => {
    const userData = localStorage.getItem("userId");
    if (!userData) {
      <Redirect push to="/" />;
    }
  }, []);

  const handleInputField = (data) => {
    if (data.currentTarget.name === "email") {
      setEmail(data.currentTarget.value);
      if (VALIDATION_REGEX.EMAIL_REGEX.test(data.currentTarget.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    } else {
      setPassword(data.currentTarget.value);
    }
  };
  // const login = async () => {
  //   setIsLoading(true);

  //   if (email === "" || password === "") {
  //     {
  //       setIsLoading(false);
  //       CustomError("Credentials not proper");
  //     }
  //   } else if (password < 5) {
  //     CustomError("Password length minimum 5 characters long");
  //     setIsLoading(false);
  //   } else {
  //     if (!emailError) {
  //       let loginObject = {
  //         UserName: email,
  //         Password: password,
  //         RememberMe: true,
  //       };
  //       await loginUser(loginObject).then(({ data }) => {
  //         
  //         let LoginApiResult = _.get(data, "result");

  //         if (LoginApiResult === undefined || LoginApiResult === null) {
  //           setIsLoading(false);
  //           const status = _.get(data, "status");
  //           if (status === 534) {
  //             CustomError("User not verified");
  //           } else if (status === 200) {
  //             CustomError("Password not matched");
  //           }
  //         } else {
  //           let userId = _.get(LoginApiResult, "Id");
  //           let userTypeObject = _.get(LoginApiResult, "user_types");
  //           let userName = _.get(LoginApiResult, "name");
  //           let userTitle = _.get(userTypeObject, "title");
  //           localStorage.setItem("userType", userTitle);
  //           localStorage.setItem("userId", userId);
  //           localStorage.setItem("userName", userName);
  //           CustomSuccess("Login Successful");
  //           // window.location = "/";
  //         }
  //       });
  //       isLoggedIn(true);
  //     } else {
  //       isLoading(false);
  //       CustomError("Credentials not proper");
  //     }
  //   }
  // };
  const login = async () => {
    setIsLoading(true);
    if (email === "" || password === "") {
      {
        setIsLoading(false);
        CustomError("All fields are required");
      }
    } else if (password.length < 5) {
      CustomError("Password length minimum 5 characters long");
      setIsLoading(false);
    } else {
      let loginObject = {
        UserName: email,
        Password: password,
        RememberMe: true,
      };

      loginUser(loginObject)
        .then(({ data }) => {
          if (data.success) {
            localStorage.setItem("userId", data.result.user.id);
            CustomSuccess("Successfully Logged in");
            setIsLoggedIn(true);
          } else {
            if (data.status === 98) {
              CustomError("Invalid credentials");
            }
          }
          setIsLoading(false);
        })
        .catch(() => {
          CustomError("All fields are required");
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <Redirect push to="/dashboard" />
      ) : (
        <div className="login__container">
          <Form className="login__form">
            <div className="login__form__heading">Login</div>
            <div className="login__form__row">
              <Inputbox
                name="email"
                placeholder="Email"
                type="email"
                ClassName="input__box"
                onChange={(e) => handleInputField(e)}
                value={email}
              />
              {emailError ? (
                <Form.Text className="text-danger">
                  Enter valid email address
                </Form.Text>
              ) : (
                <></>
              )}
            </div>
            <div className="login__form__row">
              <Inputbox
                name="password"
                placeholder="Password"
                type="password"
                ClassName="input__box"
                onChange={(e) => handleInputField(e)}
                value={password}
              />
            </div>
            {/* <div className="login__form__row__cta">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-1"
                  type="checkbox"
                  value="value1"
                />
                <label htmlFor="styled-checkbox-1">Remember Me</label>
              </div> */}
            <Button placeholder="Login" variant="btn" onClick={() => login()} />
          </Form>
        </div>
      )}
    </>
  );
}
