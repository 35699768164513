/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Nav,
  Alert,
  Dropdown,
  Col,
  ButtonGroup,
  Accordion,
  Modal,
  Form,
} from "react-bootstrap";
import productPic from "../assets/productPic.png";
import Button from "./Button";
import TrackOrderProduct from "./TrackOrderProduct";
import _ from "lodash";
import {
  updateOrderProductStatus,
  getOrderByFilter,
  getOrderById,
  GetAllOrders,
  addUpdateOrderDetailStatus,
  updateOrderLineItemsStatus,
} from "../api/order";
import { CustomError, CustomSuccess } from "../components/Toast";
import { VALIDATION_REGEX } from "../Constants";

export default function ProductList({ product, wholeOrder }) {
  debugger;
  console.log("product", product);

  useEffect(() => {
    debugger;
    getAllOrdersApiCall(product.orderIDFK);
    setProductStatusData();
  }, []);

  //#region Variables

  var [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedProductId, setselectedProductId] = useState(wholeOrder.id);
  let [firstCheckPointData, setFirstCheckPointData] = useState(null);

  const [productToSend, setProductToSend] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [orderDetailsModalVisibility, setOrderDetailsModalVisibility] =
    useState(false);
  const [amazonOrderId, setAmazonOrderId] = useState("");
  const [amazonOrderIdError, setAmazonOrderIdError] = useState(false);
  const [price, setPrice] = useState("");
  const [shippingPrice, setShippingPrice] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [shippingPriceError, setShippingPriceError] = useState(false);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  var [orderStatusLookupDetailIdfk, setOrderStatusLookupDetailIdfk] = useState(
    product.orderStatusLookupDetailIdfk
  );
  const [orderLineItemId, setOrderLineItemId] = useState(product.id);

  const [selected, setSelected] = useState("None");
  debugger;
  let orderStatus = "";
  const [productStatus, setProductStatus] = useState("");

  //#endregion

  //#region other functions
  const handleClose = () => setOrderDetailsModalVisibility(false);
  const handleShow = () => setOrderDetailsModalVisibility(true);

  const setProductStatusData = () => {
    let orderData;

    if (allOrders.length > 0 && allOrders !== null && allOrders !== undefined) {
      for (let i = 0; i < allOrders.length; i++) {
        orderData = allOrders[i];
        debugger;
      }
    }
    debugger;
    if (
      orderData !== null &&
      orderData !== undefined &&
      orderData.orderStatusLookupDetailIdfk === 8
    ) {
      debugger;
      setSelected("Order Placed");
      //   setProductStatus("Order Placed");
      //   orderStatus = "Order Placed";
    } else if (
      orderData !== null &&
      orderData !== undefined &&
      orderData.orderStatusLookupDetailIdfk === 9
    ) {
      orderStatus = "Consolidation Center";
    } else if (
      orderData !== null &&
      orderData !== undefined &&
      orderData.orderStatusLookupDetailIdfk === 10
    ) {
      orderStatus = "Distribution Center";
    } else if (
      orderData !== null &&
      orderData !== undefined &&
      orderData.orderStatusLookupDetailIdfk === 11
    ) {
      orderStatus = "Delivered";
    } else {
      orderStatus = "None";
    }
  };

  const handleChangeNone = (productId) => {
    setSelected("None");
    changeOrderStatus(productId, 18);
  };
  const handleChangeOrderPlace = (productId) => {
    setSelected("Order Placed");
    changeOrderStatus(productId, 8);
    handleShow();
  };
  const handleChangeConsolidationCenter = (productId) => {
    setSelected("Consolidation Center");
    changeOrderStatus(productId, 9);
  };
  const handleChangeDistributionCenter = (productId) => {
    setSelected("Distribution Center");
    changeOrderStatus(productId, 10);
  };
  const handleChangeDelivered = (productId) => {
    setSelected("Delivered");
    changeOrderStatus(productId, 11);
  };

  const handleChange = (event) => {
    if (event.target.name === "amazonOrderId") {
      setAmazonOrderId(event.target.value);
      if (VALIDATION_REGEX.AMAZON_ORDER_NUMBER.test(event.target.value)) {
        setAmazonOrderIdError(false);
      } else {
        setAmazonOrderIdError(true);
      }
    } else if (event.target.name === "price") {
      setPrice(event.target.value);
      if (
        VALIDATION_REGEX.DIGITIS_WITH_DECIMAL_POINT.test(event.target.value)
      ) {
        setPriceError(false);
      } else {
        setPriceError(true);
      }
    } else if (event.target.name === "shippingPrice") {
      setShippingPrice(event.target.value);
      if (
        VALIDATION_REGEX.DIGITIS_WITH_DECIMAL_POINT.test(event.target.value)
      ) {
        setShippingPriceError(false);
      } else {
        setShippingPriceError(true);
      }
    } else {
      setDeliveryDate(event.target.value);
      if (VALIDATION_REGEX.DATE_YYYY_MM_DD.test(event.target.value)) {
        setDeliveryDateError(false);
      } else {
        setDeliveryDateError(true);
      }
    }
  };

  let productName = "";
  let productImage = "";
  let productLink = "";
  let productCheckpoint = "";

  productName = _.find(product.orderLineItemMetaDTO, { orderLineKey: "Name" });
  if (productName) {
    productName = productName.orderLineValue;
  } else {
    productName = "Dummy Product";
  }

  productImage = _.find(product.orderLineItemMetaDTO, {
    orderLineKey: "Image",
  });
  if (productImage) {
    productImage = productImage.orderLineValue;
  } else {
    productImage = productPic;
  }

  productLink = _.find(product.orderLineItemMetaDTO, { orderLineKey: "Link" });
  if (productLink) {
    productLink = productLink.orderLineValue;
  } else {
    productLink = "#";
  }

  const shrinkProductname = (productName) => {
    return productName.substring(0, 15) + "..";
  };

  const handleListItem = () => {
    getOrderById(selectedProductId)
      .then((res) => {
        setFirstCheckPointData((firstCheckPointData = res.data.result));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //#endregion

  //#region Api Calls

  const getAllOrdersApiCall = (id) => {
    getOrderById(id)
      .then((res) => {
        if (res.data.success) {
          setAllOrders((allOrders = res.data.result.orderLineItem));
          setProductToSend(allOrders);
          setProductStatus(res.data.result);
          debugger;
          console.log("getOrdersById", res.data.result.orderLineItem);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const changeOrderStatus = (orderId, statusId) => {
    let orderStatusObject = {
      Id: orderId,
      userId: parseInt(localStorage.getItem("userId")),
      StatusIdfk: statusId,
    };
    updateOrderProductStatus(orderStatusObject)
      .then(({ data }) => {
        if (data.success) {
          CustomSuccess("Product Status Updated");
          // handleShow();
          // setOrderDetailsModalVisibility(true);
        }
      })
      .catch((err) => {
        CustomError("Something went wrong");
      });
  };

  const addOrder = () => {
    if (amazonOrderId === "") {
      CustomError("All fields are required");
    } else if (price === "") {
      CustomError("All fields are required");
    } else if (shippingPrice === "") {
      CustomError("All fields are required");
    } else if (deliveryDate === "") {
      CustomError("All fields are required");
    } else if (
      !amazonOrderIdError &&
      !priceError &&
      !shippingPriceError &&
      !deliveryDateError
    ) {
      let OrderLineValueObject = {
        amazon_order_id: amazonOrderId,
        price: price,
        shipping_price: shippingPrice,
        delivery_date: deliveryDate,
      };
      let jsonOrderLineValueObject = JSON.stringify(OrderLineValueObject);
      let object = {
        OrderLineIdfk: product.id,
        OrderLineKey: "CheckPoint1",
        OrderLineValue: jsonOrderLineValueObject,
      };
      console.log("object", object);
      addUpdateOrderDetailStatus(object)
        .then((response) => {
          // setProductToSend(response.data.result);

          // let data = Object.assign({},orders);
          // let object = data.orderLineItem;
          //
          // let selectedLineItem = data.orderLineItem.find(
          //   (element) => element.id === productToSend.id
          // );
          //
          // allOrders.orderLineItemMetaDTO.push(response.data.result);
          console.log("response", response);

          handleClose();
          // CustomSuccess("Order Place Successfully");
        })
        .catch((err) => {
          console.log("err", err);
        });
      orderStatusLookupDetailIdfk = 8;
      let lineItemObject = {
        Id: orderLineItemId,
        StatusIdfk: orderStatusLookupDetailIdfk,
        UserId: 2,
      };
      updateOrderLineItemsStatus(lineItemObject)
        .then((response) => {
          console.log("updateOrderLineItemsStatus response", response);
          if (response.success) {
            setProductToSend(
              response.data.result.orderLineItem.length > 0
                ? response.data.result.orderLineItem[0]
                : null
            );

            handleClose();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      CustomSuccess("Order Place Successfully");
    } else {
      CustomError("Enter valid credentials");
    }
  };

  //#endregion

  const Status = ({ value }) => {
    debugger;
    if (value === "None") {
      return (
        <Alert key="live" className="status status-Pre">
          None
        </Alert>
      );
    } else if (value === "Order Placed") {
      return (
        <Alert key="live" className="status status-live">
          Order Placed
        </Alert>
      );
    } else if (value === "Consolidation Center") {
      return (
        <Alert key="live" className="status status-hold">
          Consolidation Center
        </Alert>
      );
    } else if (value === "Distribution Center") {
      return (
        <Alert key="live" className="status status-closed">
          Distribution Center
        </Alert>
      );
    } else if (value === "Delivered") {
      return (
        <Alert key="live" className="status status-RMO">
          Deliverd
        </Alert>
      );
    } else {
      return false;
    }
  };

  return (
    <>
      <Accordion
        defaultActiveKey="0"
        onClick={() => {
          getAllOrdersApiCall(product.orderIDFK);
          setProductStatusData();
        }}
      >
        <Nav.Link
          className="order__details__product__list__entry"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Accordion.Toggle
            eventKey="1"
            className="Accordion__toggle__order__details__product__list__entry"
            onClick={() => handleListItem()}
          >
            <div className="order__details__product__list__entry">
              <div className="order__details__product__list__entry__left">
                <img
                  src={productImage}
                  alt="productPic"
                  className="order__details__product__list__entry__img"
                />
                <div className="order__details__product__list__entry__name">
                  {shrinkProductname(productName)}
                </div>
              </div>
              <div className="order__details__product__list__entry__right">
                <div className="order__details__product__list__entry__price">
                  AED {product.price}
                </div>
                <Button placeholder="Delete" variant="btn-delete" />
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <>
              <div className="order__details__product__list__entry">
                <div className="order__details__product__list__entry__left">
                  <div className="order__details__product__list__entry__price">
                    {productName}
                  </div>
                </div>
              </div>
              <div className="order__details__product__list__entry">
                <div className="order__details__product__list__entry__left">
                  <div className="order__details__product__list__entry__price">
                    Quantity: {product.qty}
                  </div>
                </div>
              </div>
              <div className="order__details__product__list__entry">
                <div className="order__details__product__list__entry__left">
                  <div className="order__details__product__list__entry__price">
                    Price: AED {product.price}
                  </div>
                </div>
              </div>
              <div className="order__details__product__list__entry">
                <div className="order__details__product__list__entry__left">
                  <div className="panel__card__content__notes">
                    <a
                      target="_blank"
                      href={productLink}
                      style={{ margin: "0em", padding: "0em" }}
                    >
                      Amazon Link
                    </a>
                  </div>
                </div>
              </div>

              {productToSend.length > 0 ? (
                <div style={{ width: "100%" }}>
                  <TrackOrderProduct
                    productData={productToSend}
                    orderLineItem={productToSend}
                    wholeOrderData={wholeOrder}
                    orderDetailsModalVisibility={orderDetailsModalVisibility}
                  />
                </div>
              ) : null}

              <div style={{ width: "100%" }}>
                <Col className="status__col">
                  <Dropdown as={ButtonGroup}>
                    <Status value={selected} />
                    <Dropdown.Toggle
                      split
                      variant="dark"
                      id="dropdown-custom-2"
                      style={{
                        backgroundColor: "#eeeeee",
                        borderColor: "#eeeeee",
                        color: "#444444",
                        height: 36,
                      }}
                    />
                    <Dropdown.Menu className="super-colors">
                      <Dropdown.Item
                        onClick={() => handleChangeNone(product.id)}
                        className="status__dropdown"
                      >
                        <div
                          className="status__option"
                          style={{
                            backgroundColor: "#848a86",
                          }}
                        ></div>
                        None
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleChangeOrderPlace(product.id)}
                        className="status__dropdown"
                      >
                        <div
                          className="status__option"
                          style={{
                            backgroundColor: "#09C956",
                          }}
                        ></div>
                        Place Order
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleChangeConsolidationCenter(product.id)
                        }
                        className="status__dropdown"
                      >
                        <div
                          className="status__option"
                          style={{
                            backgroundColor: "#ffae0c",
                          }}
                        ></div>
                        Consolidation Center
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleChangeDistributionCenter(product.id)
                        }
                        className="status__dropdown"
                      >
                        <div
                          className="status__option"
                          style={{
                            backgroundColor: "#ff4d4d",
                          }}
                        ></div>
                        Distribution Center
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleChangeDelivered(product.id)}
                        className="status__dropdown"
                      >
                        <div
                          className="status__option"
                          style={{
                            backgroundColor: "#c832fa",
                          }}
                        ></div>
                        Delivered
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </div>
              <div
                style={{
                  margin: "4em auto",
                  width: "60%",
                  height: "2px",
                  backgroundColor: "gray",
                }}
              ></div>
            </>
          </Accordion.Collapse>
        </Nav.Link>
      </Accordion>

      <Modal
        show={orderDetailsModalVisibility}
        onHide={handleClose}
        size="mg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="amazonOrderId"
                placeholder="Amazon Order ID"
                onChange={(e) => handleChange(e)}
              />
              {amazonOrderIdError ? (
                <Form.Text className="text-danger">
                  Enter valid order id
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="price"
                placeholder="Price"
                onChange={(e) => handleChange(e)}
              />
              {priceError ? (
                <Form.Text className="text-danger">
                  Price can be in numbers only
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="shippingPrice"
                placeholder="Shipping Price"
                onChange={(e) => handleChange(e)}
              />
              {shippingPriceError ? (
                <Form.Text className="text-danger">
                  Shipping price can be in numbers only
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="date"
                placeholder="Delivery Date (YYYY-MM-DD)"
                onChange={(e) => handleChange(e)}
              />
              {deliveryDateError ? (
                <Form.Text className="text-danger">
                  Enter Valid Date(YYYY-MM-DD)
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div
            onClick={() => {
              addOrder();
            }}
          >
            Save
          </div>
          <div onClick={handleClose}>Close</div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
