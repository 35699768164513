import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import {
  addUpdateOrderDetailStatus,
  getOrderById,
  updateOrderLineItemsStatus,
} from "../api/order";
import "../screens/OrderPanel.scss";
import _ from "lodash";
import { CustomError, CustomSuccess } from "../components/Toast";
import { VALIDATION_REGEX } from "../Constants";

// function OrderProduct({ productImg }) {
//   return (
//     <div className="track__order__component__top__left__order">
//       <img
//         src={productImg}
//         alt="productImg"
//         className="track__order__component__top__img"
//       />
//       <div className="track__order__component__top__left__content">
//         <div className="track__order__component__top__left__content__heading">
//           Ajmal Mizyaan for Men
//         </div>
//         <div className="track__order__component__top__left__content__info">
//           By Lorem ipsum dolor By Lorem ipsum dolor By Lorem ipsum dolor
//         </div>
//       </div>
//     </div>
//   );
// }

export default function TrackOrderProduct({
  productData,
  orderLineItem,
  wholeOrderData,
  orderDetailsModalVisibility,
  allOrders,
}) {
  debugger;
  console.log("productData", productData);
  console.log("orderLineItem", orderLineItem)
  // console.log(fetchedProductListItem.orderLineItem[0].orderLineItemMetaDTO[4]);
  const isMilestone1Done = true;
  const isMilestone2Done = true;
  const isMilestone3Done = true;
  const isMilestone4Done = false;
  console.log("OrderLine Data", orderLineItem);

  let checkpoint1Data ;
  let checkpoint2Data ;
  let checkpoint3Data ;
  let checkpoint4Data ;

  if (orderLineItem !== null && orderLineItem !== undefined) {
    let orderData;
    for(let i=0; i<orderLineItem.length; i++){
      orderData = orderLineItem[i];
      debugger;
    }
    
    let object1 = orderData.orderLineItemMetaDTO.find(
      (element) => element.orderLineKey === "CheckPoint1"
      );

    // object1 = orderLineItem.orderLineItemMetaDTO.find(
    //     (element) => element.orderLineKey === "CheckPoint1"
    //     );
      
    
    if (object1 !== undefined)
    checkpoint1Data = JSON.parse(object1.orderLineValue);
    // console.log("checkpoint1Data", checkpoint1Data);
    // let object2 = orderLineItem.orderLineItemMetaDTO.find(
    //   (element) => element.orderLineKey === "CheckPoint2"
    // );
    // if (object2 != undefined)
    // checkpoint2Data = (JSON.parse(object2.orderLineValue));

    // let object3 = orderLineItem.orderLineItemMetaDTO.find(
    //   (element) => element.orderLineKey === "CheckPoint3"
    // );
    // if (object3 != undefined)
    // checkpoint3Data = (JSON.parse(object3.orderLineValue));

    // let object4 = orderLineItem.orderLineItemMetaDTO.find(
    //   (element) => element.orderLineKey === "CheckPoint4"
    // );
    // if (object4 != undefined)
    // checkpoint4Data = (JSON.parse(object4.orderLineValue));
  }



  const [checkpoint1, setCheckpoint1] = useState(checkpoint1Data);
  
  const [checkpoint2, setCheckpoint2] = useState(checkpoint2Data);
  const [checkpoint3, setCheckpoint3] = useState(checkpoint3Data);
  const [checkpoint4, setCheckpoint4] = useState(checkpoint4Data);
  console.log(wholeOrderData.id);
  
  
  console.log(checkpoint1);
  const [amazonOrderId, setAmazonOrderId] = useState("");
  const [price, setPrice] = useState("");
  const [shippingPrice, setShippingPrice] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(wholeOrderData.id);
  const [amazonOrderIdError, setAmazonOrderIdError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [shippingPriceError, setShippingPriceError] = useState(false);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  // const [orderLineItemId, setOrderLineItemId] = useState(productData.id);
  // var [orderStatusLookupDetailIdfk, setOrderStatusLookupDetailIdfk] = useState(
  //   productData.orderStatusLookupDetailIdfk
  // );
  let [firstCheckPointData, setFirstCheckPointData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Form Validation for Order Placed

  const handleChange = (event) => {
    if (event.target.name === "amazonOrderId") {
      setAmazonOrderId(event.target.value);
      if (VALIDATION_REGEX.AMAZON_ORDER_NUMBER.test(event.target.value)) {
        setAmazonOrderIdError(false);
      } else {
        setAmazonOrderIdError(true);
      }
    } else if (event.target.name === "price") {
      setPrice(event.target.value);
      if (
        VALIDATION_REGEX.DIGITIS_WITH_DECIMAL_POINT.test(event.target.value)
      ) {
        setPriceError(false);
      } else {
        setPriceError(true);
      }
    } else if (event.target.name === "shippingPrice") {
      setShippingPrice(event.target.value);
      if (
        VALIDATION_REGEX.DIGITIS_WITH_DECIMAL_POINT.test(event.target.value)
      ) {
        setShippingPriceError(false);
      } else {
        setShippingPriceError(true);
      }
    } else {
      setDeliveryDate(event.target.value);
      if (VALIDATION_REGEX.DATE_YYYY_MM_DD.test(event.target.value)) {
        setDeliveryDateError(false);
      } else {
        setDeliveryDateError(true);
      }
    }
  };
  // const addOrder = () => {
  //   if (amazonOrderId === "") {
  //     CustomError("All fields are required");
  //   } else if (price === "") {
  //     CustomError("All fields are required");
  //   } else if (shippingPrice === "") {
  //     CustomError("All fields are required");
  //   } else if (deliveryDate === "") {
  //     CustomError("All fields are required");
  //   } else if (
  //     !amazonOrderIdError &&
  //     !priceError &&
  //     !shippingPriceError &&
  //     !deliveryDateError
  //   ) {
  //     let OrderLineValueObject = {
  //       amazon_order_id: amazonOrderId,
  //       price: price,
  //       shipping_price: shippingPrice,
  //       delivery_date: deliveryDate,
  //     };
  //     let jsonOrderLineValueObject = JSON.stringify(OrderLineValueObject);
  //     let object = {
  //       OrderLineIdfk: productData.id,
  //       OrderLineKey: "CheckPoint1",
  //       OrderLineValue: jsonOrderLineValueObject,
  //     };
  //     console.log("object", object);
  //     addUpdateOrderDetailStatus(object)
  //       .then((response) => {
  //         console.log("response", response);

  //         handleClose();
  //       })
  //       .catch((err) => {

  //         console.log("err", err);
  //       });
  //     orderStatusLookupDetailIdfk = 8;
  //     let lineItemObject = {
  //       Id: orderLineItemId,
  //       StatusIdfk: orderStatusLookupDetailIdfk,
  //       UserId: 2,
  //     };
  //     updateOrderLineItemsStatus(lineItemObject)
  //       .then((response) => {
  //         console.log("response", response);
  //       })
  //       .catch((err) => {

  //         console.log("err", err);
  //       });
  //     CustomSuccess("Order Place Successfully");
  //   } else {
  //     CustomError("Enter valid credentials");
  //   }
  // };
  // const getOrderLineItem = () => {
  //   getOrderById(id)
  //     .then((res) => {
  //       // console.log(res.data.result.orderLineItem.orderLineItemMetaDTO);
  //       let obj = res.data.result.orderLineItem.orderLineItemMetaDTO.find(
  //         (element) => element.orderLineKey === "CheckPoint1"
  //       );
  //       if (obj != undefined) console.log(JSON.parse(obj.orderLineValue));
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  return (
    <>
      <div className="track__order__component">
        {/* <div className="track__order__component__top">
          <div className="track__order__component__top__left">
            <OrderProduct productImg={productImg} />
          </div>
          <Button
            veriant="dark"
            className="track__order__component__top__right"
          >
            Cancel Order
          </Button>
        </div> */}
        <div className="track__order__component__bottom">
          <div className="track__order__component__bottom__milestone">
            {checkpoint1Data !== null && checkpoint1Data !== undefined ? (
              <div
                className="track__order__component__bottom__milestone__active"
                style={{ width: "0px" }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="track__order__component__bottom__milestone__points__1">
              {checkpoint1Data !== null && checkpoint1Data !== undefined ? (
                <div
                  className="track__order__component__bottom__milestone__points__circle__active"
                  onClick={handleShow}
                >
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Amazon Order Id: {checkpoint1Data.amazon_order_id !== undefined &&  checkpoint1Data.amazon_order_id ? checkpoint1Data.amazon_order_id : null}
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Price: {checkpoint1Data.price !== undefined && checkpoint1Data.price ? checkpoint1Data.price : null}
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Shipping Price: {checkpoint1Data.shipping_price !== undefined && checkpoint1Data.shipping_price ? checkpoint1Data.shipping_price : null}
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Delivery Date: {checkpoint1Data.delivery_date !== undefined && checkpoint1Data.delivery_date ? checkpoint1Data.delivery_date : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="track__order__component__bottom__milestone__points__circle__active"
                  // onClick={handleShow}
                >
                  <div className="track__order__component__bottom__milestone__points__circle">
                    <div className="track__order__component__bottom__milestone__points__circle__info">
                      <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                        No Record Found
                      </div>
                      <div className="track__order__component__bottom__milestone__points__circle__info__content">
                        {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore? */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="track__order__component__bottom__milestone__points__name">
                Order Placed
              </div>
            </div>
            <div className="track__order__component__bottom__milestone__points__2">
              {checkpoint2 != undefined ? (
                <div className="track__order__component__bottom__milestone__points__circle__active">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              ) : (
                <div className="track__order__component__bottom__milestone__points__circle">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              )}
              <div className="track__order__component__bottom__milestone__points__name">
                Consolidation Center
              </div>
            </div>
            <div className="track__order__component__bottom__milestone__points__3">
              {checkpoint3 != undefined ? (
                <div className="track__order__component__bottom__milestone__points__circle__active">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              ) : (
                <div className="track__order__component__bottom__milestone__points__circle">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              )}
              <div className="track__order__component__bottom__milestone__points__name">
                Distribution Center
              </div>
            </div>
            <div className="track__order__component__bottom__milestone__points__4">
              {checkpoint4 != undefined ? (
                <div className="track__order__component__bottom__milestone__points__circle__active">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              ) : (
                <div className="track__order__component__bottom__milestone__points__circle">
                  <div className="track__order__component__bottom__milestone__points__circle__info">
                    <div className="track__order__component__bottom__milestone__points__circle__info__heading">
                      Lorem, ipsum.
                    </div>
                    <div className="track__order__component__bottom__milestone__points__circle__info__content">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Veritatis totam, impedit rem error placeat nulla facilis
                      commodi veniam perferendis labore?
                    </div>
                  </div>
                </div>
              )}
              <div className="track__order__component__bottom__milestone__points__name">
                Delivered
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="track__order__component__line"></div>
      <Modal
        show={show}
        onHide={handleClose}
        size="mg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="amazonOrderId"
                placeholder="Amazon Order ID"
                onChange={(e) => handleChange(e)}
              />
              {amazonOrderIdError ? (
                <Form.Text className="text-danger">
                  Enter valid order id
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="price"
                placeholder="Price"
                onChange={(e) => handleChange(e)}
              />
              {priceError ? (
                <Form.Text className="text-danger">
                  Price can be in numbers only
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="shippingPrice"
                placeholder="Shipping Price"
                onChange={(e) => handleChange(e)}
              />
              {shippingPriceError ? (
                <Form.Text className="text-danger">
                  Shipping price can be in numbers only
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="date"
                placeholder="Delivery Date (YYYY-MM-DD)"
                onChange={(e) => handleChange(e)}
              />
              {deliveryDateError ? (
                <Form.Text className="text-danger">
                  Enter Valid Date(YYYY-MM-DD)
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            // onClick={() => {
            //   addOrder();
            // }}
            variant="success"
          >
            Add
          </Button>
          <Button onClick={handleClose} variant="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
}
