import axios from 'axios';


  export function getParentCategories() {
    return axios.get(
      process.env.REACT_APP_NEW_BASEURL + "/api/Category/GetParentCategory"
    );
  }

  export function getCategoriesByParentId(parentId) {
    return axios.get(
      process.env.REACT_APP_NEW_BASEURL + "/api/Category/GetCategoryListById?Id=" + parentId
    );
  }


  export const updateCategoryApi = (data) => {
    return axios.post(process.env.REACT_APP_NEW_BASEURL + '/api/Category/UpdateCategoryById', data, {
      headers: {
        ["Content-Type"]: "application/json",
      },
      ["axios-retry"]: {
        retries: 5,
      },
    });
  };