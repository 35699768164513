import axios from "axios";
export const GetAllProducts = (pageNumber, pageLimit) => {
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL +
      "/api/Product/GetAll?limit=" +
      pageLimit +
      "&page=" +
      pageNumber
  );
};
export const SearchProducts = (title, pageLimit, pageNumber) => {
  let query = "";

  if (title !== null && title !== undefined && title !== "" && title !== 0) {
    query += "search=" + title + "&";
  }
  if (pageNumber !== null && pageNumber !== undefined && pageNumber !== "") {
    query += "page=" + pageNumber + "&limit=" + pageLimit;
  }
  return axios.get(
    process.env.REACT_APP_NEW_BASEURL + "/api/Product/TitleSearch?" + query
  );
};
